import React from "react";

function GroupDetails(props) {
  const { group, version, groupname = "" } = props || {};
  if (!group && !groupname) {
    return null;
  }

  if (!group && groupname) {
    return (
      <section className="hc_group_detail_container">
        <h4 className="hc_group_detail_header">Not Valid Group !</h4>
      </section>
    );
  }
  return (
    <section className="hc_group_detail_container">
      <div style={{ display: "flex" }}>
        <img
          src={group?.logo}
          alt={group?.logo_display_text}
          width="64px"
          height="64px"
          style={{ borderRadius: "100%", border: "2px solid #D63296" }}
        />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            flexWrap: "wrap",
            paddingLeft: "10px",
          }}
        >
          <h4 className="hc_group_detail_header">
            Join me in seeking eternal bliss in our journeys!
          </h4>
          <h6 className="hc_group_detail_sub_header">
            {" "}
            <strong>{group?.name}</strong> resonates with HabitCircles's mission
            to{" "}
            <strong>Inspire a billion people to embrace healthy habits.</strong>
          </h6>
        </div>
      </div>

      {/* <a className="hc_download_android_btn" href={version?.apk_url}>
        <img
          src="assets/images/HabitCircles/android_robot.svg"
          className="waving_"
          width="20"
          height="20"
          style={{ marginRight: "10px" }}
        />
        DOWNLOAD HABITCIRCLES APP
      </a> */}
    </section>
  );
}

export default GroupDetails;
