import axios from "axios";

const url = process.env.REACT_APP_BACK_END_URL;

//Get group details by group subdomain 
export const getGroupDetailsData = async (subdomain) => {
    return axios.get(url + '/group/' + subdomain)
}

export const getVersionDetailsData = () => {
    return axios.get(url + '/version' )
}