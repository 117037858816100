

import { Routes, Route } from "react-router-dom"
import Home from './components/Home';
import './App.css';
import Coach from "./components/Coach";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={ <Home /> } />
        <Route path="/coach" element={ <Coach/> } />
        <Route path="/:groupname" element={ <Home/> } />
        {/* <Route path="*" element={ <PageNotFound/> } /> */}
      </Routes>
    </div>
  );
}

export default App;
