import React, { useEffect, useState } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import {
  getGroupDetailsData,
  getVersionDetailsData,
} from "../services/hc_apis";
import Header from "./Header";
import GroupDetails from "./GroupDetails";

const Home = () => {
  const { groupname } = useParams();
  const navigation = useNavigate();

  const [group, setGroup] = useState(null);
  const [version, setVersion] = useState(null);

  useEffect(() => {
    if (groupname) {
      const name = groupname?.toLowerCase();
      getGroupDetails(name);
    }

    getVersionDetails();
  }, []);

  const getGroupDetails = async (name) => {
    const result = await getGroupDetailsData(name);
    if (result.status === 200) {
      setGroup(result?.data?.data);
    } else {
      navigation("/");
    }
  };

  const getVersionDetails = async () => {
    const result = await getVersionDetailsData();
    if (result.status === 200) {
      setVersion(result?.data?.data);
    } else {
      setVersion(null);
    }
  };

  return (
    <>
      <Header group={group} />
      <GroupDetails group={group} version={version} groupname={groupname} />
      <section className="billionsuns_banner_container">
        <img
          src="assets/images/HabitCircles/hc_banner.png"
          className="billionsuns_banner_container_image"
        />
        <img
          src="assets/images/HabitCircles/hc_banner_mobile.png"
          className="billionsuns_banner_container_image_mobile"
        />
        <div className="banner_download_section">
          <a className="hc_download_android_btn" href={version?.apk_url}>
            <img
              src="assets/images/HabitCircles/android_robot.svg"
              className="waving_"
              width="20"
              height="20"
              style={{ marginRight: "10px" }}
            />
            DOWNLOAD HABITCIRCLES APP
          </a>
          <div className="hc_download_android_btn_text">
            Available for Android now, iOS version coming soon!
          </div>
        </div>
        {group && version && (
          <map name="image-map">
            <area
              target="_self"
              alt=""
              title=""
              href={version?.apk_url}
              coords="666,214,1006,272"
              shape="coachbtn"
            />
          </map>
        )}
      </section>

      {!groupname && (
        <section className="hc_coach_link_container">
          <span className="hc_coach_link_text">
            Are you a coach, looking forward to improve the engagement
            satisfaction with members? Checkout HabitCircles for Coaches!
          </span>
          <a className="hc_coach_link_btn" href="">
            HABITCIRCLES FOR COACHES
          </a>
        </section>
      )}

      <section className="hc_app_mission_container">
        <div className="hc_app_mission_header">
          <img
            src="assets/images/HabitCircles/hc_ten_bn_badge.png"
            width={"148px"}
            height={"148px"}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              textWrap: "pretty",
            }}
          >
            <h2 className="mission_subheading" style={{ textAlign: "left" }}>
              We are on a mission to
            </h2>
            <h2 className="mission_heading" style={{ textAlign: "left" }}>
              Inspire a billion people to embrace healthy habits!
            </h2>
          </div>
        </div>
        <div className="mission_multi_image">
          <img src="assets/images/HabitCircles/motivation_fades.png" />
          <img src="assets/images/HabitCircles/habits_forever.png" />
        </div>
        <div>
          <div className="mission_footer_image">
            <img
              src="assets/images/HabitCircles/waves_bottom.png"
              width={"100%"}
              height={"142px"}
            />
          </div>
        </div>
      </section>

      <section
        className="billionsuns_banner_container hc_mission_container"
        style={{
          backgroundColor: "#f3f4f6",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: "80px 80px 0px 80px",
        }}
      >
        <h2 className="mission_heading">Consistent Positive Habits form a</h2>
        <h2 className="mission_heading">Strong Foundation for your Success!</h2>
        <h5 className="mission_subheading">
          Embrace the power of consistency as you embark on your transformative
          habit-building adventure with us.
        </h5>
      </section>

      <section className="hc_download_android_contianer">
        <a className="hc_download_android_btn" href={version?.apk_url}>
          <img
            src="assets/images/HabitCircles/android_robot.svg"
            className="waving_"
            width="20"
            height="20"
            style={{ marginRight: "10px" }}
          />
          DOWNLOAD HABITCIRCLES APP
        </a>
        <div className="hc_download_android_btn_text">
          Available for Android now, iOS version coming soon!
        </div>
        {group && (
          <div className="hc_download_android_btn_text">
            <span>Use the Group Code </span>
            <span className="hc_group_detail_code">{group?.group_code} </span>
            <span>{` during sign-up in the app, to join  ${group?.name}!`}</span>
          </div>
        )}
      </section>

      <section className="hc_images_container">
        <div className="hc_images_container_block">
          <img
            src="./assets/images/HabitCircles/hc_list.svg"
            className="hc_images"
          />
          <img
            src="./assets/images/HabitCircles/hc_profile.svg"
            className="hc_images"
          />
          <img
            src="./assets/images/HabitCircles/hc_community.svg"
            className="hc_images"
          />
        </div>
      </section>

      <section className="bs_feature_section">
        <div
          className="bs_feature_container"
          style={{ backgroundColor: "#50288c" }}
        >
          <div className="bs_feature_description_block">
            <h4 className="bs_feature_heading">
              Habit Building is easierdone in groups!
            </h4>
            <h6 className="bs_feature_subheading">
              Motivation is high when you join like minded-people. Get better
              together!
            </h6>
          </div>
          <img
            src="./assets/images/HabitCircles/hc_app_banner_1.png"
            style={{
              objectFit: "contain",
              width: "100%",
              marginTop: "80px",
              height: "480px",
            }}
          />
        </div>
        <div
          className="bs_feature_container"
          style={{ backgroundColor: "#aa69c3" }}
        >
          <div className="bs_feature_description_block">
            <h4 className="bs_feature_heading">Elevate Yourself Every Day!</h4>
            <h6 className="bs_feature_subheading">
              Uncover the power of consistency with habit mastery.
            </h6>
          </div>
          <img
            src="./assets/images/HabitCircles/hc_app_banner_2.png"
            style={{
              objectFit: "contain",
              width: "100%",
              marginTop: "80px",
              height: "480px",
            }}
          />
        </div>
        <div
          className="bs_feature_container"
          style={{ backgroundColor: "#f694c5" }}
        >
          <div className="bs_feature_description_block">
            <h4 className="bs_feature_heading">Your Daily Success Partner</h4>
            <h6 className="bs_feature_subheading">
              Achieve your goals effortlessly with consistent habits
            </h6>
          </div>
          <img
            src="./assets/images/HabitCircles/hc_app_banner_3.png"
            style={{
              objectFit: "contain",
              width: "100%",
              marginTop: "80px",
              height: "480px",
            }}
          />
        </div>
        <div
          className="bs_feature_container"
          style={{ backgroundColor: "#afa5dc" }}
        >
          <div className="bs_feature_description_block">
            <h4 className="bs_feature_heading">
              Flaunt your Habit Building Progress
            </h4>
            <h6 className="bs_feature_subheading">
              Put Kaizen (Japanese term forconstant improvement) into your life
              and be a better version of yourself!
            </h6>
          </div>
          <img
            src="./assets/images/HabitCircles/hc_app_banner_4.png"
            style={{
              objectFit: "contain",
              width: "100%",
              marginTop: "80px",
              height: "480px",
            }}
          />
        </div>
      </section>

      <footer className="footer" style={{ marginTop: "0px" }}>
        <div className="links_container">
          {/* <div className="contact_us_link_container">
              <h3 className="links_container_heading">CONTACT US</h3>
              <div style={{ display: 'flex', alignItems: 'flex-start', marginTop: '20px'}}>
                <img src="assets/images/shared/icn_address.svg" />
                <div
                 style={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginLeft: '25px',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',
                    marginTop: '-3px',
                 }}
                >
                  <span className="link_container_links_text">
                    H No.13-14, 2nd floor, Kothnur Main Rd,
                  </span>
                  <span className="link_container_links_text">
                    Opposite Corporation Bank,
                  </span>
                  <span className="link_container_links_text">
                    JP Nagar 7th Phase, Bengaluru,
                  </span>
                  <span className="link_container_links_text">
                    Karnataka - 560078
                  </span>
                </div>
              </div>
              <div style={{ display: 'flex', alignItems: 'flex-start', marginTop: '10px' }}>
                <img src="assets/images/shared/icn_email.svg" />
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        marginLeft: '25px',
                        justifyContent: 'flex-start',
                        alignItems: 'flex-start',
                        marginTop: '-3px',
                    }}
                >
                  <span className="link_container_links_text"
                    >hello@heartfultech.com</span
                  >
                </div>
              </div>
            </div> */}
          <div className="contact_us_link_container policy_link_container">
            <h3 className="links_container_heading">LINKS</h3>
            <div
              style={{
                display: "flex",
                alignItems: "flex-start",
                marginTop: "20px",
              }}
            >
              <div
                data-toggle="modal"
                data-target="#serviceTermsModal"
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                  flexDirection: "row",
                }}
              >
                <span
                  className="link_container_links_text"
                  style={{ textDecoration: "underline", cursor: "pointer" }}
                >
                  Terms and Conditions
                </span>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "flex-start",
                marginTop: "10px",
              }}
            >
              <div
                data-toggle="modal"
                data-target="#privacyPolicyModal"
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                  flexDirection: "row",
                }}
              >
                <span
                  className="link_container_links_text"
                  style={{ textDecoration: "underline", cursor: "pointer" }}
                >
                  Privacy Policy
                </span>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "flex-start",
                marginTop: "10px",
              }}
            >
              <div
                data-toggle="modal"
                data-target="#refundPolicyModal"
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                  flexDirection: "row",
                }}
              >
                <span
                  className="link_container_links_text"
                  style={{ textDecoration: "underline", cursor: "pointer" }}
                >
                  Refunds / Cancellation
                </span>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="ht_logo_container">
            <img src="assets/images/shared/HT_logo.svg" />
          </div> */}
      </footer>

      {/* MODAL"S STARTING */}

      {/* Privacy Policy Modal */}
      <div
        className="modal fade"
        id="privacyPolicyModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="privacyPolicyModal"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header modal_heading">
              <span className="modalHeading" id="privacyPolicyModalTitle">
                Privacy Policy
              </span>
              <button
                type="button"
                className="btn btn-tertiary"
                data-dismiss="modal"
              >
                Close
              </button>
            </div>
            <div className="modal-body">
              <p className="sectionHeading">
                SECTION 1 - WHAT DO WE DO WITH YOUR INFORMATION?
              </p>
              <p className="sectionText">
                When you sign up to our website we collect the personal
                information you give such as your name, email address. And when
                you register to our services we collect some more personal
                information such as:
              </p>
              <p className="sectionText">
                Full name ,Email Address ,Date of birth ,Phone number
                ,Occupation ,Your current and permanent address,Document of ID
                Proof ,Document of 10th grade marks card ,Document of degree
                certificate ,Your photo
              </p>
              <p className="sectionHeading">Information Use</p>
              <p className="sectionText">
                The information collected by our website is used for the purpose
                it is intended and to provide specific and better services to
                individuals. As applicable, the information you provide to us
                may be used to:ide to us may be used to:
              </p>
              <ul>
                <li className="sectionText" style={{ marginLeft: "40px" }}>
                  Contact you for Events, Training and other specific updates
                </li>
                <li className="sectionText" style={{ marginLeft: "40px" }}>
                  Contact you for membership status
                </li>
                <li className="sectionText" style={{ marginLeft: "40px" }}>
                  Contact you for the programs that you are associated with us
                  for
                </li>
                <li className="sectionText" style={{ marginLeft: "40px" }}>
                  Update you on studies, papers, articles and surveys carried
                  out by HEARTFUL TECH PVT LTD.
                </li>
                <li className="sectionText" style={{ marginLeft: "40px" }}>
                  Monitor or improve the use of HEARTFUL TECH PVT LTD. website
                </li>
                <li className="sectionText" style={{ marginLeft: "40px" }}>
                  Monitor compliance with our Site privacy policy
                </li>
                <li className="sectionText" style={{ marginLeft: "40px" }}>
                  Provide special information materials to you from us and/or
                  our respective affiliates, subsidiaries and other third
                  parties
                </li>
                <li className="sectionText" style={{ marginLeft: "40px" }}>
                  For aggregating information such as demographics and Site
                  usage statistics
                </li>
              </ul>
              <p className="sectionText">
                Our website reserves the right to share your information with
                organizations affiliated with HEARTFUL TECH PVT LTD. without
                your permission. Collected information will not be sold,
                exchanged, transferred or given to any other company for any
                reason whatsoever without your consent, other than the
                exceptions like required by law, search warrants, other court
                orders or national exigent circumstances
              </p>
              <p className="sectionHeading">SECTION 2 - CONSENT</p>
              <p className="sectionText">How do you get my consent?</p>
              <p className="sectionText">
                When you provide us with personal information to complete a
                transaction, verify your credit card, place an order, arrange
                for a delivery or return a purchase, we imply that you consent
                to our collecting it and using it for that specific reason only.
                If we ask for your personal information for a secondary reason,
                like marketing, we will either ask you directly for your
                expressed consent, or provide you with an opportunity to say no.
              </p>
              <p className="sectionText">How do I withdraw my consent?</p>
              <p className="sectionText">
                If after you opt-in, if you change your mind, you may withdraw
                your consent for us to contact you, for the continued
                collection, use or disclosure of your information, at anytime,
                by contacting us at hello@heartfultech.com or mailing us at: H
                No.13-14, 2nd floor, Kothnur Main Rd, Opposite Corporation Bank,
                JP Nagar 7th Phase, Bengaluru, Karnataka - 560078.
              </p>
              <p className="sectionHeading">SECTION 3 - DISCLOSURE</p>
              <p className="sectionText">
                We may disclose your personal information if we are required by
                law to do so or if you violate our Terms of Service.
              </p>
              <p className="sectionHeading">SECTION 4 - PAYMENT</p>
              <p className="sectionText">
                We use Razorpay for processing payments. We/Razorpay do not
                store your card data on their servers. The data is encrypted
                through the Payment Card Industry Data Security Standard
                (PCI-DSS) when processing payment. Your purchase transaction
                data is only used as long as is necessary to complete your
                purchase transaction. After that is complete, your purchase
                transaction information is not saved.
              </p>
              <p className="sectionText">
                Our payment gateway adheres to the standards set by PCI-DSS as
                managed by the PCI Security Standards Council, which is a joint
                effort of brands like Visa, MasterCard, American Express and
                Discover.
              </p>
              <p className="sectionText">
                PCI-DSS requirements help ensure the secure handling of credit
                card information by our store and its service providers.
              </p>
              <p className="sectionHeading">SECTION 5 - THIRD-PARTY SERVICE</p>
              <p className="sectionText">
                In general, the third-party providers used by us will only
                collect, use and disclose your information to the extent
                necessary to allow them to perform the services they provide to
                us.
              </p>
              <p className="sectionText">
                However, certain third-party service providers, such as payment
                gateways and other payment transaction processors, have their
                own privacy policies in respect to the information we are
                required to provide to them for your purchase-related
                transactions.
              </p>
              <p className="sectionText">
                For these providers, we recommend that you read their privacy
                policies so you can understand the manner in which your personal
                information will be handled by these providers.
              </p>
              <p className="sectionText">
                In particular, remember that certain providers may be located in
                or have facilities that are located a different jurisdiction
                than either you or us. So if you elect to proceed with a
                transaction that involves the services of a third-party service
                provider, then your information may become subject to the laws
                of the jurisdiction(s) in which that service provider or its
                facilities are located.
              </p>
              <p className="sectionText">
                Once you leave our website or are redirected to a third-party
                website or application, you are no longer governed by this
                Privacy Policy or our website's Terms of Service.
              </p>
              <p className="sectionText">Links</p>
              <p className="sectionText">
                When you click on links on our website, they may direct you away
                from our site. We are not responsible for the privacy practices
                of other sites and encourage you to read their privacy
                statements.
              </p>
              <p className="sectionHeading">SECTION 6 - SECURITY</p>
              <p className="sectionText">
                To protect your personal information, we take reasonable
                precautions and follow industry best practices to make sure it
                is not inappropriately lost, misused, accessed, disclosed,
                altered or destroyed.
              </p>
              <p className="sectionHeading">SECTION 7 –SESSION COOKIES</p>
              <p className="sectionText">
                We use Session cookies to maintain session of user. It is not
                used to personally identify you on other websites. Session
                cookies are deleted when you leave our website. They help keep
                track of your progress so that you don't have to enter the same
                information more than once.
              </p>
              <p className="sectionHeading">SECTION 8 - AGE OF CONSENT</p>
              <p className="sectionText">
                By using this site, you represent that you are at least the age
                of majority in your state or province of residence, or that you
                are the age of majority in your state or province of residence
                and you have given us your consent to allow any of your minor
                dependents to use this site.
              </p>
              <p className="sectionHeading">
                SECTION 9 - CHANGES TO THIS PRIVACY POLICY
              </p>
              <p className="sectionText">
                We reserve the right to modify this privacy policy at any time,
                so please review it frequently. Changes and clarifications will
                take effect immediately upon their posting on the website. If we
                make material changes to this policy, we will notify you here
                that it has been updated, so that you are aware of what
                information we collect, how we use it, and under what
                circumstances, if any, we use and/or disclose it.
              </p>
              <p className="sectionText">
                If our website is acquired or merged with another company, your
                information may be transferred to the new owners so that we may
                continue to sell products to you.
              </p>
              <p className="sectionHeading">
                QUESTIONS AND CONTACT INFORMATION
              </p>
              <p className="sectionText">
                If you would like to: access, correct, amend or delete any
                personal information we have about you, register a complaint, or
                simply want more information contact our Privacy Compliance
                Officer at hello@heartfultech.com or by mail at HEARTFUL TECH
                PVT LTD, H No.13-14, 2nd floor, Kothnur Main Rd, Opposite
                Corporation Bank, JP Nagar 7th Phase, Bengaluru, Karnataka -
                560078.
              </p>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Refund Policy Modal */}
      <div
        className="modal fade"
        id="refundPolicyModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="refundPolicyModal"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header modal_heading">
              <span className="modalHeading" id="refundPolicyModalTitle">
                Refund Policy
              </span>
              <button
                type="button"
                className="btn btn-tertiary"
                data-dismiss="modal"
              >
                Close
              </button>
            </div>
            <div className="modal-body">
              <p className="sectionText">
                We do not offer refund or exchange of any products or services
                once purchased.
              </p>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Terms Policy Modal  */}
      <div
        className="modal fade"
        id="serviceTermsModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="serviceTermsModalTitle"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header modal_heading">
              <span className="modalHeading" id="serviceTermsModalTitle">
                Terms of Service
              </span>
              <button
                type="button"
                className="btn btn-tertiary"
                data-dismiss="modal"
              >
                Close
              </button>
            </div>
            <div className="modal-body">
              <p className="sectionHeading">
                This website is operated by HEARTFUL TECH PVT LTD. Throughout
                the site, the terms 'we', 'us' and 'our' refer to HEARTFUL TECH
                PVT LTD. HEARTFUL TECH PVT LTD offers this website, including
                all information, tools and services available from this site to
                you, the user, conditioned upon your acceptance of all terms,
                conditions, policies and notices stated here.
              </p>
              <p className="sectionText">
                We do not offer refund or exchange of any products or services
                once purchased.
              </p>
              <p className="sectionText">
                By visiting our site and/ or purchasing something from us, you
                engage in our Service and agree to be bound by the following
                terms and conditions ('Terms of Service', 'Terms'), including
                those additional terms and conditions and policies referenced
                herein and/or available by hyperlink. These Terms of Service
                apply to all users of the site, including without limitation
                users who are browsers, vendors, customers, merchants, and/ or
                contributors of content.
              </p>
              <p className="sectionText">
                Please read these Terms of Service carefully before accessing or
                using our website. By accessing or using any part of the site,
                you agree to be bound by these Terms of Service. If you do not
                agree to all the terms and conditions of this agreement, then
                you may not access the website or use any services. If these
                Terms of Service are considered an offer, acceptance is
                expressly limited to these Terms of Service.
              </p>
              <p className="sectionText">
                Any new features or tools which are added to the current website
                shall also be subject to the Terms of Service. You can review
                the most current version of the Terms of Service at any time on
                this page. We reserve the right to update, change or replace any
                part of these Terms of Service by posting updates and/or changes
                to our website. It is your responsibility to check this page
                periodically for changes. Your continued use of or access to the
                website following the posting of any changes constitutes
                acceptance of those changes.
              </p>
              <p className="sectionHeading">
                SECTION 1 - ONLINE PURCHASE TERMS
              </p>
              <p className="sectionText">
                By agreeing to these Terms of Service, you represent that you
                are at least the age of majority in your state or province of
                residence, or that you are the age of majority in your state or
                province of residence and you have given us your consent to
                allow any of your minor dependents to use this site.
              </p>
              <p className="sectionText">
                You may not use our products for any illegal or unauthorized
                purpose nor may you, in the use of the Service, violate any laws
                in your jurisdiction (including but not limited to copyright
                laws).
              </p>
              <p className="sectionText">
                You must not transmit any worms or viruses or any code of a
                destructive nature.
              </p>
              <p className="sectionText">
                A breach or violation of any of the Terms will result in an
                immediate termination of your Services.
              </p>
              <p className="sectionHeading">SECTION 2 - GENERAL CONDITIONS</p>
              <p className="sectionText">
                We reserve the right to refuse service to anyone for any reason
                at any time.
              </p>
              <p className="sectionText">
                You understand that your content (not including credit card
                information), may be transferred unencrypted and involve (a)
                transmissions over various networks; and (b) changes to conform
                and adapt to technical requirements of connecting networks or
                devices. Credit card information is always encrypted during
                transfer over networks.
              </p>
              <p className="sectionText">
                You agree not to reproduce, duplicate, copy, sell, resell or
                exploit any portion of the Service, use of the Service, or
                access to the Service or any contact on the website through
                which the service is provided, without express written
                permission by us.
              </p>
              <p className="sectionText">
                The headings used in this agreement are included for convenience
                only and will not limit or otherwise affect these Terms.
              </p>
              <p className="sectionHeading">
                SECTION 3 - ACCURACY, COMPLETENESS AND TIMELINESS OF INFORMATION
              </p>
              <p className="sectionText">
                We are not responsible if information made available on this
                site is not accurate, complete or current. The material on this
                site is provided for general information only and should not be
                relied upon or used as the sole basis for making decisions
                without consulting primary, more accurate, more complete or more
                timely sources of information. Any reliance on the material on
                this site is at your own risk.
              </p>
              <p className="sectionText">
                his site may contain certain historical information. Historical
                information, necessarily, is not current and is provided for
                your reference only. We reserve the right to modify the contents
                of this site at any time, but we have no obligation to update
                any information on our site. You agree that it is your
                responsibility to monitor changes to our site.
              </p>
              <p className="sectionHeading">
                SECTION 4 - MODIFICATIONS TO THE SERVICE AND PRICES
              </p>
              <p className="sectionText">
                Prices for our services are subject to change without notice.
              </p>
              <p className="sectionText">
                We reserve the right at any time to modify or discontinue the
                Service (or any part or content thereof) without notice at any
                time.
              </p>
              <p className="sectionText">
                We shall not be liable to you or to any third-party for any
                modification, price change, suspension or discontinuance of the
                Service.
              </p>
              <p className="sectionHeading">SECTION 5 - PRODUCTS OR SERVICES</p>
              <p className="sectionText">
                Certain products or services may be available exclusively online
                through the website. These products or services may have limited
                quantities and are subject to return or exchange only according
                to our Return Policy.
              </p>
              <p className="sectionText">
                We have made every effort to display as accurately as possible
                the colors and images of our products that appear at the
                website. We cannot guarantee that your computer monitor's
                display of any color will be accurate.
              </p>
              <p className="sectionText">
                We reserve the right, but are not obligated, to limit the sales
                of our products or Services to any person, geographic region or
                jurisdiction. We may exercise this right on a case-by-case
                basis. We reserve the right to limit the quantities of any
                products or services that we offer. All descriptions of products
                or product pricing are subject to change at anytime without
                notice, at the sole discretion of us. We reserve the right to
                discontinue any service at any time. Any offer for any product
                or service made on this site is void where prohibited.
              </p>
              <p className="sectionText">
                We do not warrant that the quality of any products, services,
                information, or other material purchased or obtained by you will
                meet your expectations, or that any errors in the Service will
                be corrected.
              </p>
              <p className="sectionHeading">
                SECTION 6 - ACCURACY OF BILLING AND ACCOUNT INFORMATION
              </p>
              <p className="sectionText">
                We reserve the right to refuse any order you place with us. We
                may, in our sole discretion, limit or cancel quantities
                purchased per person, per household or per order. These
                restrictions may include orders placed by or under the same
                customer account, the same credit card, and/or orders that use
                the same billing and/or shipping address. In the event that we
                make a change to or cancel an order, we may attempt to notify
                you by contacting the e-mail and/or billing address/phone number
                provided at the time the order was made. We reserve the right to
                limit or prohibit orders that, in our sole judgment, appear to
                be placed by dealers, resellers or distributors.
              </p>
              <p className="sectionText">
                You agree to provide current, complete and accurate purchase and
                account information for all purchases made at our store. You
                agree to promptly update your account and other information,
                including your email address and credit card numbers and
                expiration dates, so that we can complete your transactions and
                contact you as needed.
              </p>
              <p className="sectionText">
                For more detail, please review our Refund Policy.
              </p>
              <p className="sectionHeading">SECTION 7 - OPTIONAL TOOLS</p>
              <p className="sectionText">
                We may provide you with access to third-party tools over which
                we neither monitor nor have any control nor input.
              </p>
              <p className="sectionText">
                You acknowledge and agree that we provide access to such tools
                'as is' and 'as available' without any warranties,
                representations or conditions of any kind and without any
                endorsement. We shall have no liability whatsoever arising from
                or relating to your use of optional third-party tools.
              </p>
              <p className="sectionText">
                Any use by you of optional tools offered through the site is
                entirely at your own risk and discretion and you should ensure
                that you are familiar with and approve of the terms on which
                tools are provided by the relevant third-party provider(s).
              </p>
              <p className="sectionText">
                We may also, in the future, offer new services and/or features
                through the website (including, the release of new tools and
                resources). Such new features and/or services shall also be
                subject to these Terms of Service.
              </p>
              <p className="sectionHeading">SECTION 8 - THIRD-PARTY LINKS</p>
              <p className="sectionText">
                Certain content, products and services available via our Service
                may include materials from third-parties.
              </p>
              <p className="sectionText">
                Third-party links on this site may direct you to third-party
                websites that are not affiliated with us. We are not responsible
                for examining or evaluating the content or accuracy and we do
                not warrant and will not have any liability or responsibility
                for any third-party materials or websites, or for any other
                materials, products, or services of third-parties.
              </p>
              <p className="sectionText">
                We are not liable for any harm or damages related to the
                purchase or use of goods, services, resources, content, or any
                other transactions made in connection with any third-party
                websites. Please review carefully the third-party's policies and
                practices and make sure you understand them before you engage in
                any transaction. Complaints, claims, concerns, or questions
                regarding third-party products should be directed to the
                third-party.
              </p>
              <p className="sectionHeading">
                SECTION 9 - USER COMMENTS, FEEDBACK AND OTHER SUBMISSIONS
              </p>
              <p className="sectionText">
                If, at our request, you send certain specific submissions (for
                example contest entries) or without a request from us you send
                creative ideas, suggestions, proposals, plans, or other
                materials, whether online, by email, by postal mail, or
                otherwise (collectively, 'comments'), you agree that we may, at
                any time, without restriction, edit, copy, publish, distribute,
                translate and otherwise use in any medium any comments that you
                forward to us. We are and shall be under no obligation (1) to
                maintain any comments in confidence; (2) to pay compensation for
                any comments; or (3) to respond to any comments.
              </p>
              <p className="sectionText">
                We may, but have no obligation to, monitor, edit or remove
                content that we determine in our sole discretion are unlawful,
                offensive, threatening, libelous, defamatory, pornographic,
                obscene or otherwise objectionable or violates any party’s
                intellectual property or these Terms of Service.
              </p>
              <p className="sectionText">
                You agree that your comments will not violate any right of any
                third-party, including copyright, trademark, privacy,
                personality or other personal or proprietary right. You further
                agree that your comments will not contain libelous or otherwise
                unlawful, abusive or obscene material, or contain any computer
                virus or other malware that could in any way affect the
                operation of the Service or any related website. You may not use
                a false e-mail address, pretend to be someone other than
                yourself, or otherwise mislead us or third-parties as to the
                origin of any comments. You are solely responsible for any
                comments you make and their accuracy. We take no responsibility
                and assume no liability for any comments posted by you or any
                third-party.
              </p>
              <p className="sectionHeading">
                SECTION 10 - PERSONAL INFORMATION
              </p>
              <p className="sectionText">
                Your submission of personal information through the website is
                governed by our Privacy Policy.
              </p>
              <p className="sectionHeading">
                SECTION 11 - ERRORS, INACCURACIES AND OMISSIONS
              </p>
              <p className="sectionText">
                Occasionally there may be information on our site or in the
                Service that contains typographical errors, inaccuracies or
                omissions that may relate to product descriptions, pricing,
                promotions, offers, product shipping charges, transit times and
                availability. We reserve the right to correct any errors,
                inaccuracies or omissions, and to change or update information
                or cancel orders if any information in the Service or on any
                related website is inaccurate at any time without prior notice
                (including after you have submitted your order).
              </p>
              <p className="sectionText">
                We undertake no obligation to update, amend or clarify
                information in the Service or on any related website, including
                without limitation, pricing information, except as required by
                law. No specified update or refresh date applied in the Service
                or on any related website, should be taken to indicate that all
                information in the Service or on any related website has been
                modified or updated.
              </p>
              <p className="sectionHeading">SECTION 12 - PROHIBITED USES</p>
              <p className="sectionText">
                In addition to other prohibitions as set forth in the Terms of
                Service, you are prohibited from using the site or its content:
                (a) for any unlawful purpose; (b) to solicit others to perform
                or participate in any unlawful acts; (c) to violate any
                international, federal, provincial or state regulations, rules,
                laws, or local ordinances; (d) to infringe upon or violate our
                intellectual property rights or the intellectual property rights
                of others; (e) to harass, abuse, insult, harm, defame, slander,
                disparage, intimidate, or discriminate based on gender, sexual
                orientation, religion, ethnicity, race, age, national origin, or
                disability; (f) to submit false or misleading information; (g)
                to upload or transmit viruses or any other type of malicious
                code that will or may be used in any way that will affect the
                functionality or operation of the Service or of any related
                website, other websites, or the Internet; (h) to collect or
                track the personal information of others; (i) to spam, phish,
                pharm, pretext, spider, crawl, or scrape; (j) for any obscene or
                immoral purpose; or (k) to interfere with or circumvent the
                security features of the Service or any related website, other
                websites, or the Internet. We reserve the right to terminate
                your use of the Service or any related website for violating any
                of the prohibited uses.
              </p>
              <p className="sectionHeading">
                SECTION 13 - DISCLAIMER OF WARRANTIES; LIMITATION OF LIABILITY
              </p>
              <p className="sectionText">
                We do not guarantee, represent or warrant that your use of our
                service will be uninterrupted, timely, secure or error-free.
              </p>
              <p className="sectionText">
                We do not warrant that the results that may be obtained from the
                use of the service will be accurate or reliable.
              </p>
              <p className="sectionText">
                You agree that from time to time we may remove the service for
                indefinite periods of time or cancel the service at any time,
                without notice to you.
              </p>
              <p className="sectionText">
                You expressly agree that your use of, or inability to use, the
                service is at your sole risk. The service and all products and
                services delivered to you through the service are (except as
                expressly stated by us) provided 'as is' and 'as available' for
                your use, without any representation, warranties or conditions
                of any kind, either express or implied, including all implied
                warranties or conditions of merchantability, merchantable
                quality, fitness for a particular purpose, durability, title,
                and non-infringement.
              </p>
              <p className="sectionText">
                In no case shall HEARTFUL TECH PVT LTD., our directors,
                officers, employees, affiliates, agents, contractors, interns,
                suppliers, service providers or licensors be liable for any
                injury, loss, claim, or any direct, indirect, incidental,
                punitive, special, or consequential damages of any kind,
                including, without limitation lost profits, lost revenue, lost
                savings, loss of data, replacement costs, or any similar
                damages, whether based in contract, tort (including negligence),
                strict liability or otherwise, arising from your use of any of
                the service or any products procured using the service, or for
                any other claim related in any way to your use of the service or
                any product, including, but not limited to, any errors or
                omissions in any content, or any loss or damage of any kind
                incurred as a result of the use of the service or any content
                (or product) posted, transmitted, or otherwise made available
                via the service, even if advised of their possibility. Because
                some states or jurisdictions do not allow the exclusion or the
                limitation of liability for consequential or incidental damages,
                in such states or jurisdictions, our liability shall be limited
                to the maximum extent permitted by law.
              </p>
              <p className="sectionHeading">SECTION 14 - INDEMNIFICATION</p>
              <p className="sectionText">
                You agree to indemnify, defend and hold harmless HEARTFUL TECH
                PVT LTD. and our parent, subsidiaries, affiliates, partners,
                officers, directors, agents, contractors, licensors, service
                providers, subcontractors, suppliers, interns and employees,
                harmless from any claim or demand, including reasonable
                attorneys, fees, made by any third-party due to or arising out
                of your breach of these Terms of Service or the documents they
                incorporate by reference, or your violation of any law or the
                rights of a third-party.
              </p>
              <p className="sectionHeading">SECTION 15 - SEVERABILITY</p>
              <p className="sectionText">
                In the event that any provision of these Terms of Service is
                determined to be unlawful, void or unenforceable, such provision
                shall nonetheless be enforceable to the fullest extent permitted
                by applicable law, and the unenforceable portion shall be deemed
                to be severed from these Terms of Service, such determination
                shall not affect the validity and enforceability of any other
                remaining provisions.
              </p>
              <p className="sectionHeading">SECTION 16 - TERMINATION</p>
              <p className="sectionText">
                The obligations and liabilities of the parties incurred prior to
                the termination date shall survive the termination of this
                agreement for all purposes. These Terms of Service are effective
                unless and until terminated by either you or us. You may
                terminate these Terms of Service at any time by notifying us
                that you no longer wish to use our Services, or when you cease
                using our site.
              </p>
              <p className="sectionText">
                If in our sole judgment you fail, or we suspect that you have
                failed, to comply with any term or provision of these Terms of
                Service, we also may terminate this agreement at any time
                without notice and you will remain liable for all amounts due up
                to and including the date of termination; and/or accordingly may
                deny you access to our Services (or any part thereof).
              </p>
              <p className="sectionHeading">SECTION 17 - ENTIRE AGREEMENT</p>
              <p className="sectionText">
                The failure of us to exercise or enforce any right or provision
                of these Terms of Service shall not constitute a waiver of such
                right or provision. These Terms of Service and any policies or
                operating rules posted by us on this site or in respect to The
                Service constitutes the entire agreement and understanding
                between you and us and govern your use of the Service,
                superseding any prior or contemporaneous agreements,
                communications and proposals, whether oral or written, between
                you and us (including, but not limited to, any prior versions of
                the Terms of Service). Any ambiguities in the interpretation of
                these Terms of Service shall not be construed against the
                drafting party.
              </p>
              <p className="sectionHeading">SECTION 18 - GOVERNING LAW</p>
              <p className="sectionText">
                These Terms of Service and any separate agreements whereby we
                provide you Services shall be governed by and construed in
                accordance with the laws of India and jurisdiction of Bengaluru,
                Karnataka.
              </p>
              <p className="sectionHeading">
                SECTION 19 - CHANGES TO TERMS OF SERVICE
              </p>
              <p className="sectionText">
                You can review the most current version of the Terms of Service
                at any time at this page. We reserve the right, at our sole
                discretion, to update, change or replace any part of these Terms
                of Service by posting updates and changes to our website. It is
                your responsibility to check our website periodically for
                changes. Your continued use of or access to our website or the
                Service following the posting of any changes to these Terms of
                Service constitutes acceptance of those changes.
              </p>
              <p className="sectionHeading">SECTION 20 - CONTACT INFORMATION</p>
              <p className="sectionText">
                Questions about the Terms of Service should be sent to us at
                hello@heartfultech.com
              </p>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Modal Ends */}

      <div
        className="copyright_footer"
        style={{ backgroundColor: "#f3f4f6", marginTop: "0px" }}
      >
        <span className="copyright_text">
          {" "}
          &copy; 2024 HEARTFUL TECH Pvt Ltd.
        </span>
        {/* <img
            src="assets/images/shared/independent_bootstrapped.svg"
            className="logo bootstrap_logo"
          /> */}
      </div>
    </>
  );
};

export default Home;
