import React, { useEffect, useState } from 'react'
import { getVersionDetailsData } from '../services/hc_apis'

function Coach() {

  const [version, setVersion] = useState(null)

  useEffect(() => {
    getVersionDetails()
}, [])
  
const getVersionDetails = async () => {
    const result = await getVersionDetailsData();
    if (result.status === 200) {
      setVersion(result?.data?.data)
    } else {
      setVersion(null)
    }
}

  return (
    <>
        <header className="site_header" data-sticky-shrink="false">
            <a href="/" className="logo">
                <img
                  src="assets/images/HabitCircles/hc_coach_logo.svg"
                  alt="Coach Logo"
                  className="logo"
                />
            </a>
            <div className="header_menu_item">
              <a className="header_menu_item_link" href="">
                  <img
                      src="assets/images/shared/icn_email.svg"
                      className="waving_"
                      width="20"
                      height="20"
                      style={{ marginRight: '10px'}}
                  />
                WRITE TO US
              </a>
            </div>
        </header>
        <section className='hc_caoch_banner_container'>
            <img src="assets/images/HabitCircles/hc_hero_illustration_new.svg" usemap="#image-map" style={{ width: 'inherit'}} />
            <map name="image-map">
                <area target="_self" alt="" title="" href={version?.apk_url} coords="537,169,903,235" shape="coachbtn" />
            </map>
        </section>
        <section className='hc_coach_usage_container'>  
            <div className='hc_coach_row'>
                <div className='hc_coach_cell' style={{backgroundColor: '#AFA5DC'}}>
                    <div className='hc_coach_cell_content'>Increase the engagement <br /> satisfaction with members</div>
                    <img src="assets/images/HabitCircles/hc_coach_illusion_2.png" />
                </div>
                <div className='hc_coach_cell' style={{backgroundColor: '#AA69C3'}}>
                    <div className='hc_coach_cell_content'>Enable long-term self <br /> transformation by empowering <br /> your members</div>
                    <img src="assets/images/HabitCircles/hc_coach_illusion_3.png" />
                </div>
            </div>
            <div className='hc_coach_row'>
                <div className='hc_coach_cell' style={{backgroundColor: '#F694C5'}}>
                    <div className='hc_coach_cell_content'>Spread your <br /> brand organically</div>
                    <img src="assets/images/HabitCircles/hc_coach_illusion_4.png" />
                </div>
                <div className='hc_coach_cell' style={{backgroundColor: '#D63296'}}>
                    <div className='hc_coach_cell_content'>Attract new members <br /> through testified expressions <br /> of members.</div>
                    <img src="assets/images/HabitCircles/hc_coach_illusion_1.png" />
                </div>
            </div>
        </section>
        <section className='hc_coach_dashboard_container'>
            <h3 className='hc_coach_dashboard_text'>Effectively monitor your students’ progress <br /> through the dashboard</h3>
            <img src="assets/images/HabitCircles/dashboard_mac.svg" />
        </section>
        <section>
        <footer className="footer" style={{ marginTop: '0px'}}>
          <div className="links_container">
            <div className="contact_us_link_container">
              <h3 className="links_container_heading">CONTACT US</h3>
              <div style={{ display: 'flex', alignItems: 'flex-start', marginTop: '20px'}}>
                <img src="assets/images/shared/icn_address.svg" />
                <div
                 style={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginLeft: '25px',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',
                    marginTop: '-3px',
                 }}
                >
                  <span className="link_container_links_text">
                    H No.13-14, 2nd floor, Kothnur Main Rd,
                  </span>
                  <span className="link_container_links_text">
                    Opposite Corporation Bank,
                  </span>
                  <span className="link_container_links_text">
                    JP Nagar 7th Phase, Bengaluru,
                  </span>
                  <span className="link_container_links_text">
                    Karnataka - 560078
                  </span>
                </div>
              </div>
              <div style={{ display: 'flex', alignItems: 'flex-start', marginTop: '10px' }}>
                <img src="assets/images/shared/icn_email.svg" />
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        marginLeft: '25px',
                        justifyContent: 'flex-start',
                        alignItems: 'flex-start',
                        marginTop: '-3px',
                    }}
                >
                  <span className="link_container_links_text">hello@heartfultech.com</span>
                </div>
              </div>
            </div>
            <div className="contact_us_link_container policy_link_container">
              <h3 className="links_container_heading">LINKS</h3>
              <div style={{ display: 'flex', alignItems: 'flex-start', marginTop: '20px' }}>
                <div
                  data-toggle="modal"
                  data-target="#serviceTermsModal"
                  style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start', flexDirection: 'column' }}
                >
                  <span
                    className="link_container_links_text"
                    style={{ textDecoration: 'underline', cursor: 'pointer' }}
                  >
                    Terms and Conditions
                  </span>
                </div>
              </div>
              <div style={{ display: 'flex', alignItems: 'flex-start', marginTop: '10px' }}>
                <div
                  data-toggle="modal"
                  data-target="#privacyPolicyModal"
                  style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start', flexDirection: 'column' }}
                >
                  <span
                    className="link_container_links_text"
                    style={{ textDecoration: 'underline', cursor: 'pointer' }}
                  >
                    Privacy Policy
                  </span>
                </div>
              </div>
              <div style={{ display: 'flex', alignItems: 'flex-start', marginTop: '10px' }}>
                <div
                  data-toggle="modal"
                  data-target="#refundPolicyModal"
                  style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start', flexDirection: 'column' }}
                >
                  <span
                    className="link_container_links_text"
                    style={{ textDecoration: 'underline', cursor: 'pointer' }}
                  >
                    Refunds / Cancellation
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="ht_logo_container">
            <img src="assets/images/shared/HT_logo.svg" />
          </div>
        </footer>
        </section>
    </>
  )
}

export default Coach
