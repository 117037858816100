import React from 'react'

function Header(props) {
  const { group } = props || {}

  if (group) {
    return (
        <header className="site_header" data-sticky-shrink="false">
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                <a href="/" className="logo_with_group">
                    <img
                      src="assets/images/HabitCircles/hc_logo.svg"
                      alt="Canvas Logo"
                      className="logo_with_group"
                    />
                </a>
                <h3 className='header_group_name'>{group?.name}</h3>
            </div>
            {/* {
              !group && (
                <div className="header_menu_item">
                  <a className="header_menu_item_link" href="">
                      <img
                          src="assets/images/shared/waving-hand_emoji.png"
                          className="waving_"
                          width="20"
                          height="20"
                          style={{ marginRight: '10px'}}
                      />
                    Say Hi!
                  </a>
                </div>
              )
            } */}
            
        </header>
    )
  }

  return (
    <header className="site_header" data-sticky-shrink="false">
        <a href="/" className="logo">
            <img
              src="assets/images/HabitCircles/hc_logo.svg"
              alt="Canvas Logo"
              className="logo"
            />
        </a>
        {/* <div className="header_menu_item">
          <a className="header_menu_item_link" href="">
              <img
                  src="assets/images/shared/waving-hand_emoji.png"
                  className="waving_"
                  width="20"
                  height="20"
                  style={{ marginRight: '10px'}}
              />
            Say Hi!
          </a>
        </div> */}
    </header>
  )
}

export default Header
